<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="datatable.queries.per_page"
                      class="ml-1 mr-1"
                      size="sm"
                      :options="[10, 15, 25, 50, 100]"
                      @change="getBusinesses"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="datatable.queries.search"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                  <button
                    class="ml-1 btn btn-primary btn-sm"
                    @click="getBusinesses"
                  >
                    <i class="uil uil-search-alt"></i>
                  </button>
                </div>
              </div>
            </div>
            <template v-if="!loadingBusinesses">
              <div class="table-responsive mb-0">
                <b-table
                  :items="businesses"
                  :fields="datatable.columns"
                  :sort-by.sync="datatable.queries.sort_by"
                  :sort-desc.sync="datatable.queries.sort_desc"
                  :per-page="datatable.queries.per_page"
                  :no-local-sorting="true"
                  @sort-changed="sortChanged"
                >
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                  <template v-slot:cell(actions)="data">
                    <b-button
                      variant="primary"
                      size="sm"
                      title="Sign In"
                      :disabled="loadingImpersonate"
                      @click="impersonate(data.item.owner.id)"
                    >
                      <b-spinner v-if="loadingImpersonate" small />
                      <i v-else class="uil uil-sign-out-alt"></i>
                    </b-button>
                    <router-link
                      :to="{
                        name: 'admin.businesses.edit',
                        params: { id: data.item.id },
                      }"
                      class="btn btn-success btn-sm ml-1"
                    >
                      <i class="uil uil-edit"></i>
                    </router-link>
                    <b-button
                      variant="danger"
                      size="sm"
                      title="Delete"
                      class="ml-1"
                      :disabled="loadingRemove === data.item.id"
                      @click="remove(data.item)"
                    >
                      <b-spinner v-if="loadingRemove === data.item.id" small />
                      <i v-else class="uil uil-trash"></i>
                    </b-button>
                  </template>
                </b-table>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <div class="float-left mt-2">
                    Total {{ totalBusinesses }} businesses
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                        v-model="datatable.queries.page"
                        :total-rows="totalBusinesses"
                        :per-page="datatable.queries.per_page"
                        @change="onPageChanged"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showDeleteModal" title="Delete agency">
      <p v-if="selectedBusiness"
        >Are you sure you want to delete business "{{
          selectedBusiness.name
        }}"?</p
      >
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showDeleteModal = false"
          >Cancel</b-button
        >
        <b-button variant="danger" @click="handleRemove">Delete</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      datatable: {
        columns: [
          { key: 'id', label: '#', sortable: true },
          { key: 'name', sortable: true },
          { key: 'owner.email', label: 'Email', sortable: true },
          { key: 'agency.name', label: 'Agency', sortable: true },
          { key: 'actions' },
        ],
        queries: {
          per_page: 10,
          page: 1,
          status: 'active',
          search: null,
          sort_by: 'id',
          sort_desc: false,
        },
      },
      showDeleteModal: false,
      selectedBusiness: null,
      loadingBusinesses: false,
      loadingImpersonate: false,
      loadingRemove: null,
    }
  },

  computed: {
    businesses() {
      return this.$store.getters['business/all'] || []
    },

    totalBusinesses() {
      return this.$store.getters['business/total']
    },
  },

  created() {
    this.$store.dispatch('business/setCurrentPage', 'index')
  },

  mounted() {
    if (this.$store.getters['business/all'] === null) {
      this.getBusinesses()
    } else {
      this.datatable.queries = this.$store.getters['business/queries']
    }
  },

  methods: {
    sortChanged(args) {
      this.datatable.queries.sort_by = args.sortBy
      this.datatable.queries.sort_desc = args.sortDesc
      this.datatable.queries.page = 1
      this.getBusinesses()
    },

    onPageChanged(page) {
      this.datatable.queries.page = page
      this.getBusinesses()
    },

    getBusinesses() {
      this.loadingBusinesses = true

      this.$store
        .dispatch('business/getAll', {
          queries: this.datatable.queries,
          page: 'index',
        })
        .then((res) => {
          this.loadingBusinesses = false
        })
        .catch(() => {
          this.loadingBusinesses = false
        })
    },

    impersonate(userId) {
      this.loadingImpersonate = true

      this.$store
        .dispatch('auth/impersonate', userId)
        .then((res) => {
          this.$store
            .dispatch('auth/getProfile')
            .then((user) => {
              this.$router.push('/' + user.role.toLocaleLowerCase())
              this.loadingImpersonate = false
            })
            .catch(() => {
              this.loadingImpersonate = false
            })
        })
        .catch(() => {
          this.loadingImpersonate = false
        })
    },

    remove(business) {
      this.showDeleteModal = true
      this.selectedBusiness = business
    },

    handleRemove() {
      this.showDeleteModal = false
      this.loadingRemove = this.selectedBusiness.id
      this.$store
        .dispatch('business/archive', this.selectedBusiness.id)
        .then(() => {
          this.loadingRemove = null
          this.getBusinesses()
        })
        .catch(() => {
          this.loadingRemove = null
        })
    },
  },
}
</script>
